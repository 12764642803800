import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorLogFO} from 'api/entities';
import {Observable, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {apiVersion} from '../../../api-version.constant';
import {getSuppressErrorToastContext} from '../shared.service';
import {Alcedo7User} from '../user/avelon-user.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorsLogEntity {
  private errors = [];
  private readonly sendErrorsDebounce: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient) {
    // @ts-ignore
    window.alcedoErrorLog = window.onerror = this.onError.bind(this);

    // @ts-ignore
    window.alcedoInitialErrors.forEach(error => this.onError.apply(this, error));

    if (window.ReportingObserver) {
      new window.ReportingObserver(reports => reports.forEach(report => this.sendReportFromObserver(report)), {buffered: true}).observe();
    }
    this.sendErrorsDebounce.pipe(debounceTime(5000)).subscribe(() => this.sendErrors());
  }

  private static decorateErrorWithAppInfo(errorObj) {
    errorObj.buildNumber = null;
    errorObj.applicationVersion = environment.version;
    errorObj.clientId = Alcedo7User && Alcedo7User.currentUser ? Alcedo7User.currentUser.clientId : null;
    errorObj.userId = Alcedo7User && Alcedo7User.currentUser ? Alcedo7User.currentUser.id : null;
  }

  static detectBrowser(userAgent) {
    const ua = userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|crios|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return {
        name: 'IE',
        version: tem[1] || ''
      };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return {
          name: tem[1].replace('OPR', 'Opera'),
          version: tem[2]
        };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);
    if (tem != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
  }

  private sendErrors(): void {
    if (this.errors.length > 0 && environment.production === true) {
      this.saveErrors();
    }
  }

  private saveErrors(): void {
    if (!Alcedo7User.currentUser) {
      return;
    }
    const oldLog = JSON.parse(JSON.stringify(this.errors));
    const logToSend = [];
    oldLog.forEach(log => {
      if (!logToSend.find(l => l.title === log.title && l.payload === log.payload)) {
        logToSend.push(log);
      }
    });
    this.errors = [];
    this.http.post(apiVersion + 'logs/errorlog', logToSend, {context: getSuppressErrorToastContext()}).subscribe();
  }

  // eslint-disable-next-line complexity
  private onError(event, source, fileno, columnNumber, error): void {
    let stack;
    let message;
    if (event && event.message) {
      message = event.message;
      stack = error ? error.stack : event.stack ? event.stack : '';
    } else if (event && event.statusText) {
      message = event.statusText;
      stack = JSON.stringify(event.data);
    } else if (source && source.message) {
      message = source.message;
      stack = source.stack;
    } else if (error && error.message) {
      message = error.message;
      if (error.error && error.error.stack) {
        stack = error.error.stack;
      } else if (error.error && error.error.error && error.error.error.stack) {
        stack = error.error.error.stack;
      }
    } else {
      message = JSON.stringify(event);
    }
    const lineColumn = fileno ? fileno + ':' + columnNumber : '';
    const errorObjectToSend = {
      title: message,
      lineColumn,
      windowWidth: document.documentElement.clientWidth,
      windowHeight: document.documentElement.clientHeight,
      os: navigator.platform,
      browser: navigator.userAgent,
      url: location.href,
      payload: stack
    };

    ErrorsLogEntity.decorateErrorWithAppInfo(errorObjectToSend);
    this.errors.push(errorObjectToSend);
    this.sendErrorsDebounce.next();
  }

  private sendReportFromObserver(report): void {
    if (report?.body?.message?.includes('Deprecation messages are stored in the devtools-frontend')) {
      return;
    }
    const errorObjectToSend = {
      title: report.type,
      lineColumn: report.body.lineNumber + ':' + report.body.columnNumber,
      windowWidth: document.documentElement.clientWidth,
      windowHeight: document.documentElement.clientHeight,
      os: navigator.platform,
      browser: navigator.userAgent,
      url: report.url,
      payload: report.body.message + '\n' + report.body.sourceFile
    };

    ErrorsLogEntity.decorateErrorWithAppInfo(errorObjectToSend);
    this.errors.push(errorObjectToSend);
    this.sendErrorsDebounce.next();
  }

  getLogs(fromDate: number): Observable<ErrorLogFO[]> {
    return this.http.get<ErrorLogFO[]>(apiVersion + 'logs/errorlog/' + fromDate);
  }
}
